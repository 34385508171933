body {
  font-family: "Lato", sans-serif;
  background: white;
  font-size: 14px;
  position: relative;
  color: #838383;
  line-height: 1.8;
  overflow-x: hidden;
}
a, button {
  color: white;
  text-decoration: none;
  -webkit-transition: all .4s ease-out 0s;
  transition: all .4s ease-out 0s;
}
a:hover, button:hover {
  color: white;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: #279f3b;
}
button {
  border:0;
}
/* Arapça dili seçildiğinde sağdan sola yönlendirme */
.ar {
  direction: rtl;
  text-align: right;
}
.ar .dropdown-menu {
  text-align: right;
}

header {
  position: relative;
  z-index: 9;
  width: 100%;
  min-height: 100px;
}
header:before {
  background: hsla(0, 0%, 100%, .1);
  bottom: 4px;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
}
.lang-dropdown .dropdown-toggle:after {
  display: none;
}
.lang-dropdown .fi {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-size: cover;
  background-position: center center !important;
}

.lang-dropdown .dropdown-toggle {
  background: transparent;
}
.ar .lang-dropdown .dropdown-toggle {
  margin-right: 0 !important;
  margin-left: 16px;
}
.lang-dropdown .dropdown-item {
  display: flex !important;
  align-items: center !important;
}
.lang-dropdown .dropdown-item .fi {width: 30px;height: 30px;margin-right: 10px;}
.lang-dropdown .dropdown-menu {
  padding: 20px;
  margin: 0 !important;
  position: absolute !important;
  left: -50% !important;
  right: -50% !important;
  margin: 0px auto !important;
}
.ar .lang-dropdown .dropdown-item .fi {
  margin-left: 10px;
  margin-right: 0 !important;
}
.lang-dropdown .dropdown-menu li a, .lang-dropdown .dropdown-menu li button {
  padding: 0 !important;
  line-height: 50px;
}
.dropdown-item.active, .dropdown-item:active {
  background: inherit;
  color: inherit;
}
.lang-dropdown .dropdown-menu li:hover a {
  background: transparent;
}
.lang-dropdown .dropdown-menu li:hover a {
  color: #279f3b;
  background: transparent;
}
ul {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
.logo img {
  max-width: 100px;;
}
.radius {
  border-radius: 12px;
}
.sticky {
  padding: 16px 0;
}
.sticky-fixed {
  position: fixed;
  top: 0;
  background: #121117;
  width: 100%;
}
.navbar-toggler {
  font-size: 34px;
  color: #34dd50;
}
.main-menu ul.navbar-nav {
  margin: 0px auto;
}

.main-menu ul > li > a.active, .main-menu ul > li > a:hover, .main-menu ul > li > button.active, .main-menu ul > li > button:hover {
  color: #279f3b !important;
}
.button-default {
  padding: 0px 36px;
  border-radius: 3px;
  font-size: 15px;
  font-weight: 600;
  transition: all .4s ease-out 0s;
  background-size: 100% auto;
  background: #279f3b;
  line-height: 60px;
  display: inline-block;
  text-align: center;
  cursor: pointer;

}
.button-default:hover {
  background-size: 200% auto;
}
.button-default:hover {
  background: white;
  color: black;
}
.button-default i, .button-lined i {
  padding-right: 6px;
}
.button-lined {
  border: 2px solid #ffffff;
  display: inline-block;
  bottom: 0;
  border-radius: 36px;
  padding: 16px 2.6rem;
  margin: 0px auto;
  color: #ffffff;
  font-weight: 600;
}

.home {
  position: relative;
  background-image: url(./assets/home-bg.jpg);
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll, fixed;
  margin-top: -100px;
  z-index: 1;
  background-color: #030303;
  min-height: 550px;
}
#home:before {
  background: #000;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
}
.ar .home {
  direction: ltr;
}
#home .container {
  z-index: 2;
  position: relative;
}
.welcome-screen {
  color: white;
  padding: 14rem 0 13rem 0;
}
.welcome-screen h1 {
  font-size: 4.2rem;
  font-weight: 900;
  margin-bottom: 2rem;
  line-height: 1;
}
.welcome-screen h1 span {
  display: block;
}
.welcome-screen h1 .border-text {
  position: relative;
  animation-delay: .4s;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
  opacity: .8;
  display: block;
  line-height: 1.4;
}
.welcome-screen p {
  color: #ffffff;
  font-size: 22px;
  line-height: 1.4;
}
.welcome-screen em {
  font-style: normal;
  color: #3691ff;
  letter-spacing: 6px;
}
.modal-content-lightbox {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  pointer-events: auto;
  /* background-clip: padding-box; */
  background: none;
}
.modal-title {
  font-weight: 700;
  color: #181818;
}
.application-details h3 {
  color: black;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
  border-bottom: 1px solid #dadada;
  padding-bottom: 12px;
}

.application-details p strong {
  color: #181717;
}
.lightbox-close {
  position: absolute;
  top: 40px;
  right: 40px;
  background: white;
  width: 42px;
  height: 42px;
  border-radius: 50%;
}

/* advanced content */
.feature-column  {
  text-align: center;
}
.advanced-content-2 {
  margin: 0 30px;
}
.advanced-content {
  justify-content: flex-start !important;
  display: flex !important;
}
.sub-title {
  font-weight: 600;
  color: #279f3b;
  letter-spacing: 3px;
  font-size: 16px;
  position: relative;
  margin: 0;
}

.feature-column h2 {
  font-size: 46px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #202020;
}
.advanced-content .icon-box {
  background: #0047ff;
  min-width: 100px;
  height: 100px;
  border-radius: 28px;
  margin-right: 1.8rem;
  text-align: center;
  line-height: 100px;
  font-size: 30px;
  color: white;
}
.advanced-content .content-box h3 {
  color: white;
  font-weight: 700;
}
.feature-column p {
  font-size: 12px;
  line-height: 1.8;
}
.advanced-content-2 .icon-box i {
  background-image: linear-gradient(150deg, #dc2751 0%, #f52b5a 78%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
}

.advanced-content-2 .icon-box {
  margin-bottom: 2rem;
}
.advanced-content-2 h3 {
  color: #1b1b1b;
  font-weight: 700;
  font-size: 1.5rem;
}

.advanced-content-2 p {
  line-height: 1.8;
  font-size: 16px;
  color: #7b7b7b;
}
.react-multi-carousel-list {
  padding-bottom: 30px;
}
.react-multi-carousel-dot button {
  background: transparent !important;
  border-color: #dc2751 !important;
}
.react-multi-carousel-dot--active button {
  background: #dc2751 !important;
}
/* advanced content */

/* ads content */
.gray-bg {
  background: #f0f0f3;
}
/* ads content */

/* block style */
.main-blocks {
  z-index: 1;
  position: relative;
  margin-top: -80px;
}
.block-syle-01 {
  background: white;
  padding: 50px;
}
.gray-box {
  background: #f8f8f8;
}
.row-shadow {
  box-shadow: 0 2px 18.9px 2.1px rgba(0, 0, 0, .04);
  position: relative;
  background: white;
}
.block-syle-01 h2 {
  font-weight: 700;
  color: black;
  font-size: 20px;
}
.block-syle-01 p {
  margin: 0;
}
.block-icon {
  color: #63cc75;
  font-size: 36px;
}
/* block style */

/* text block */
.text-block-icon {
  height: 60px;
  color: #dc2751;
  border-radius: 12px;
  text-align: center;
  font-size: 36px;
  line-height: 60px;
  margin-right: 20px;
}
.text-block h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 12px;
  color: #1b1b1b;
}

.text-block p {
  color: #7b7b7b;
  line-height: 1.8;
  font-size: 15px;
}
.feature-column em {
  font-style: normal;
  max-width: 584px;
  display: block;
  margin: 0px auto;
  font-size: 16px;
  line-height: 1.8;
  color: #7b7b7b;
}
.block-head h3 {
  font-size: 48px;
  font-weight: 900;
  color: #1b1b1b;
  margin: 0;
}
.block-head p {
  color: #dc2751;
  font-weight: 600;
  font-size: 16px;
  position: relative;
  margin: 0;
}
p.text-content {
  margin: 20px 0;
  color: #7b7b7b;
  line-height: 1.8;
  font-size: 15px;
}
/* text block */

/* faqs */

.accordion-faqs .accordion-button {
  color: #232323;
  border: 0 !important;
  font-size: 18px !important;
  padding: 24px 24px;
  background: #f0f0f3;
  font-weight: 700;
  border-radius: 12px !important;
}

.accordion-faqs
.accordion-item {
  background: transparent;
  border: 0 !important;
  color: #635a5a;
  margin-bottom: 20px;
}

.accordion-button:not(.collapsed) {
  border: 0 !important;
  box-shadow: none !important;
  background: #279f3b;
  color: white;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.accordion-body {
  background: #f0f0f3;
  padding: 2.4rem 2rem;
}
.accordion-faqs .accordion-button[aria-expanded="true"]:after {
  position: absolute;
  right: 18px;
  font-size: 20px;
  line-height: 36px;
  width: 36px;
  text-align: center;
  transform: rotate(-180deg);
  transition: all 0.3s ease;
  color: #ffffff;
  border-radius: 6px;
  height: 36px;
  background-image: none;
  font-family: "Font Awesome 5 Free";
  content: "\f107";
}
.accordion-faqs .accordion-button[aria-expanded="false"]:after {
  position: absolute;
  right: 18px;
  font-size: 17px;
  line-height: 36px;
  width: 36px;
  transform: rotate(0deg);
  transition: all 0.3s ease;
  text-align: center;
  color: #279f3b;
  background-size: 150%;
  border-radius: 6px;
  height: 36px;
  background-image: none;
  font-family: "Font Awesome 5 Free";
  content: "\f107";
}
.accordion-faqs .accordion-button:focus {
  outline: none;
  box-shadow: none;
}
.accordion-faqs {
  counter-reset: accordioncounter;
}

.accordion-faqs .accordion-button:before {
  counter-increment: accordioncounter;
  content: counters(accordioncounter, ".") ".";
  margin: 0 1rem;
  color: #121117;
}
.accordion-faqs .accordion-button[aria-expanded="true"]:before {
  color: #ffffff;
}
/* faqs */

/* card */
.card {
  padding: 62px 42px;
  border-radius: 9px;
  min-height: 320px;
  margin-bottom: 2rem;
  transition: all .4s ease-out 0s;
  background-color: transparent;
  border: 1px solid #e9f7e5;
}
.help-card h4 {
  color: black;
  font-weight: 700;
  font-size: 20px;
  margin: 20px 0;
}
.help-card:hover {
  background: #279f3b;
  color: white;
}

.help-card:hover  i, .help-card:hover  h4 {
  color: white !important;
}
.help-card:hover p {
  color: white;
}
.box-number {
  color: transparent;
  -webkit-text-stroke: 2px rgb(39 159 59 / 11%);
  opacity: .8;
  font-weight: 900;
  font-size: 80px;
  position: absolute;
  bottom: -5px;
  right: 0;
  line-height: 1;
}
.help-card:hover .box-number {
  -webkit-text-stroke: 2px rgb(255 255 255 / 30%);
}
.card i {
  font-size: 44px;
  line-height: 44px;
  color: #63cc75;
}
.card p {
  line-height: 1.8;
  font-size: 15px;
  color: #7b7b7b;
}
.card:not(.no-hover):hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
.help-card p {
  margin: 0;
}
.card-style-02 {
  background: #16182d;
  padding: 2.4rem;
  border-radius: 36px;
}

.card-style-02 h4 {
  color: white;
  font-weight: 700;
  margin-bottom: 24px;
}

.card-style-02 p {
  font-size: 16px;
  line-height: 1.8;
}
/* card */

/* breadcrumb */
.breadbrumb-section {
  position: relative;
  padding: 12rem 0 8rem 0;
  margin-top: -100px;
  background:  url("./assets/breadbrumb-bg.webp");
  text-align: center;
  color: white;
}
.breadbrumb-section:before {
  opacity: 0.7;
  background: black;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.breadcrumb-container {
  z-index: 1;
  position: relative;
}
.breadcrumb-container h1 {
  font-size: 48px;
  color: #fff;
  position: relative;
  line-height: 1.25em;
  margin-bottom: 0;
  font-weight: 900;
}
.breadcrumbs li:first-child a {
  margin-right: 20px;
  color: #4ed264;
}
.breadcrumbs li {
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
}
/* breadcrumb */

/* content style 01 */
.content-style-01 h2 {
  font-weight: 900;
  color: #1b1b1b;
  font-size: 3rem;
  margin-bottom: 2rem;
}
.content-style-01 h3 {
  font-size: 2rem;
  font-weight: 900;
  color: #1b1b1b;
}
.content-style-01 h4 {
  font-size: 1.6rem;
  font-weight: 900;
  color: #1b1b1b;
}
.content-style-01 h5 {
  color: #279f3b;
  font-family: "Covered By Your Grace", cursive;
}
.content-style-01 p {
  margin-bottom: 2rem;
}
.policy-content p {
  margin-bottom: 14px;
}
.policy-content a {
  color: black;
  text-decoration: underline;
}
.content-style-01 table p {
  margin: 0;
}
.content-style-01 table p a {
  color: inherit;
}
.content-style-01 ul li {
  margin-bottom: 14px;
}
.content-style-01 {
  color: #7b7b7b;
  line-height: 2;
}
ul.check-list li {
  display: flex;
  line-height: 2;
}

ul.check-list li i {
  color: #279f3b;
  margin-right: 8px;
}
/* content style 01 */

/* content style 02 */
.content-style-02 h2 {
  font-size: 44px;
  color: #1b1b1b;
  font-weight: 800;
  position: relative;
  line-height: 1;
  padding-top: 26px;
}
.content-style-02 h2:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 2px;
  background: #279f3b;
  top: 0;
  left: 0;
}
.content-style-02 p {
  color: #7b7b7b;
  line-height: 1.8;
  font-size: 15px;
}
/* content style 02 */

/* content style 03 */

.content-style-03 ul li {
  margin-bottom: 12px;
}

.content-style-03 strong {
  font-weight: 400;
}

.content-style-03 h3 {
  font-size: 32px;
  font-weight: 900;
  margin-bottom: 24px;
  margin-top: 30px;
  color: #1b1b1b;
}
/* content style 03 */

/* policy styles */
.policy-content h1 {
  font-size: 28px;
  font-weight: 700;
  color: #1b1b1b;
  margin-bottom: 30px;
  font-family: "Inter", sans-serif;
  letter-spacing: 0.3px;
}
.policy-content h2 {
  font-size: 20px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  letter-spacing: 0.3px;
  margin-top: 30px;
}
.policy-content h3 {
  font-size: 16px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  letter-spacing: 0.3px;
  margin-bottom: 20px;
  margin-top: 30px;
}
.policy-content ul li {
  margin: 0;
  list-style: auto;
}
.policy-content ul {
  padding-left: 14px;
  margin-bottom: 20px;
}
.policy-content b {
  color: black;
}
/* policy styles */
/* contact section */
.contact-section {
  padding: 6rem 0;
  background: url(./assets/contact-bg.webp) black;
  background-attachment: fixed;
  background-size: cover;
}
.contact-section {
  text-align: center;
  color: white;
  position: relative;
}

.contact-section:before {
  background: black;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0.7;
}
.contact-section .container {
  position: relative;
}

.contact-section h2 {
  font-size: 5rem;
  font-weight: 800;
}
.contact-section h2 span {
  position: relative;
  -webkit-animation-delay: .4s;
  animation-delay: .4s;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
.contact-section p {
  color: #5aee73;
  font-family: "Covered By Your Grace", cursive;
  font-size: 24px;
  margin: 0;
}
.form-control {
  border-radius: 4px;
  padding: 16px 20px;
  font-size: 14px;
  color: #1b1b1b;
  border: 2px solid #efefef;
}
.form-control:focus {
  border-color: #279f3b;
  box-shadow: none;
}
.form-select {
  border-radius: 4px;
  padding: 16px 20px;
  font-size: 14px;
  color: #1b1b1b;
  border: 2px solid #efefef;
}
button.form-tooltip {
  background: none;
  color: #3e3f3e;
}
.upload-row {
  border: 1px dashed rgb(163 173 165);
  padding: 20px;
  text-align: center;
  cursor: pointer;
  background: #fbfbfb;
}
.upload-row p {
  margin-bottom: 0;
}
/* contact section */

/* app form */
.app-form h2 {
  color: black;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 20px;
  padding-bottom: 0;
}
.step {
  background: #279f3b;
  color: white;
  width: 48px;
  display: inline-block;
  line-height: 48px;
  text-align: center;
  border-radius: 0;
  margin-right: 12px;
  font-size: 16px;
  font-weight: 900;
  clip-path: polygon(100% 0, 100% 66%, 72% 100%, 0 100%, 0 0);
}
.ar .step {
  margin-right: 0;
  margin-left: 12px;
}
.step i {
  font-size: 22px;
}
.form-section {
  counter-reset: stepcounter;
}
.form-box .step:before {
  counter-increment: stepcounter;
  content: "0" counters(stepcounter, ".");
  margin: 0 1rem;
  color: white;
}



.form-box {
  border: 1px solid #e0e0e0;
  padding: 26px;
}
.form-check-input:checked {
  background-color: #279f3b;
  border-color: #279f3b;
}
form .button-default:hover {
  background: #121117;
  color: white;
}
.button-link {
  color: #121117;
}

.button-link:hover {
  color: #279f3b;
}
a.terms-link {
  color: #279f3b;
  text-decoration: underline;
  font-weight: 600;
}
/* app form */

/* blog section */
.blog-summary {
  background-color: #fff;
  margin: -35px 20px 0 20px;
  position: relative;
  z-index: 1;
  border-radius: 10px;
  transition: all ease 0.4s;
  padding: 32px;
  transition: all ease 0.4s;
  bottom: 0;
}
.blog-summary h3 {
  font-weight: 700;
  color: black;
  font-size: 24px;
}
.blog-slide-item:hover h3 {
  color: white;
  margin: 0;
}
.blog-summary p {
  margin: 0;
}
.blog-slide-item {
  padding: 0px 20px !important;
  overflow: hidden;
  margin: 0 !important;
}
.blog-slide-item .image-box img {
  border-radius: 12px !important;
}
.blog-slide-item:hover .blog-summary {
  margin-top: -104px;
  background-color: #dc2751;
  border-color: transparent;
  color: white;
  padding-bottom: 100px;
}
.blog-date {
  position: absolute;
  top: -20px;
  z-index: 3;
  background: #dc2751;
  color: white;
  border-radius: 4px;
  padding: 5px 14px;
  font-size: 13px;
}
.blog-slide-item:hover .blog-date {
  background: white;
  color: black;
}
.button-style-2 {
  background: white;
  width: 44px;
  height: 44px;
  display: inline-block;
  border-radius: 50%;
  color: #333131;
  text-align: center;
  line-height: 46px;
  font-size: 26px;
  position: absolute;
  bottom: 42px;
  left: 60%;
  opacity: 0;
}

.blog-slide-item:hover .button-style-2 {
  left: 30px;
  opacity: 1;
}
.button-style-2:hover {
  color: #dc2751;
}
.blog-section .react-multi-carousel-list {
  padding-bottom: 4rem;
}
/* blog section */
/* scroll top */
.scroll-to-top {
  position: fixed;
  bottom: 40px;
  right: 30px;
  z-index: 1000;
}

.scroll-button {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s;
}

.scroll-button svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
}

.progress-circle {
  width: 100%;
  height: 100%;
}

.progress-circle circle {
  fill: none;
  stroke-width: 5px;

}

.progress-circle circle:first-child {
  stroke: rgba(255, 255, 255, 0.3);
}

.progress-circle circle:last-child {
  stroke-dasharray: 282.6;
  stroke-dashoffset: 282.6;
  stroke: #279f3b;
  transition: stroke-dashoffset 0.3s;
}

.arrow-up {
  position: relative;
  color: white;
  font-size: 24px;
}
.scroll-button i {
  color: #279f3b;
  font-size: 30px;
  line-height: 54px;
}

/* scroll top */
.social-icons > a {
  margin: 5px;
  width: 45px;
  height: 45px;
  font-size: 20px;
  background: #279f3b;
  color: white !important;
  border-radius: 3px;
  overflow: hidden;
  transition: all 0.3s ease 0s;
  text-align: center;
  line-height: 45px;
  align-items: center !important;
  display: flex;
  justify-content: center;
}
.social-icons > a:hover {
  background: #279f3b;
}

.terms-modal .modal-content {
  background: #141417;
}
.social-icons a svg {
  max-width: 100%;
  width: 100% !important;
  fill: white;
}
.terms-modal .modal-header {
  color: white;
  font-weight: 600;
  border-bottom: 1px solid #302c2c;
}
.terms-modal .modal-footer {
  border-top: 1px solid #302c2c;
}

.terms-modal .btn-secondary {
  background: #224fd8;
  border: 0;
  font-size: 16px;
  padding: 10px 24px;
}
.button-close {
  background: transparent;
  border: 0;
  color: white;
  font-size: 20px;
}
.copyright {
  font-size: 14px;
  margin-top: 1rem;
  margin-bottom: 0;
  color: #b0b0b0;
}
footer {
  background: #101010;
  padding: 4rem 0;
}
.CookieConsent {
  background: #1c1b22 !important;
}
.policy-link.ms-2 {
  color: #2ac544;
  text-decoration: underline;
}
@keyframes animation-01 {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(0);
  }

  75% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(0);
  }
}

.animation-01 img {
  animation: animation-01 6s infinite linear alternate;
}
@media (max-width: 1024px) {
  .py-6 {
    padding-top: 4.6rem !important;
    padding-bottom: 4.6rem !important;
  }
  /* sidebar */
  .sidebar {
    background: #0f111c;
    width: 80% !important;
    max-width: 340px;
    padding: 0;
  }
  .sidebar h5 {
    color: white;
  }
  .sidebar .button-close {
    color: #34dd50;
    font-size: 24px;
  }
  .sidebar .offcanvas-body {
    padding-top: 0;
    padding-bottom: 0;
  }
  .sidebar-menu {
    margin: 0;
    padding: 0;
  }
  .sidebar-menu .navbar-nav {
    display: flex;
    flex-direction: column !important;
    width: 100%;

  }
  .sidebar-menu .navbar-nav > li > a {
    color: white;
    display: block;
    line-height: 52px;
    border-bottom: 1px solid #ffffff1a;
    padding: 0px !important;
    transition: all .4s ease-out 0s;
    font-weight: 600;
  }
  .sidebar-menu .dropdown-menu li a {
    color: white;
    font-size: 14px;
    font-weight: 300;
    line-height: 38px;
    padding: 0;
  }
  .sidebar-menu .navbar-nav .nav-link.active, .sidebar-menu .navbar-nav .show>.nav-link {
    color:#279f3b
  }
  .sidebar-menu ul li .dropdown-menu {
    background: no-repeat;
    padding: 10px 0px 10px 24px;
    position: relative !important;
  }
  .nav-link:focus, .nav-link:hover {
    color: #dc2751;
  }
  .sidebar-menu .dropdown-toggle::after {
    border: 0;
    position: absolute;
    right: 14px;
    font-size: 22px;
    color: #34dd50;
    content: "\f22c";
    font-family: bootstrap-icons !important;
    font-weight: 400;
  }
  .sidebar-menu .dropdown-toggle[aria-expanded="true"]:after {
    content: "\f238";
  }
}
/* sidebar */
@media (min-width: 1025px) {
  .py-6 {
    padding-top: 5.4em !important;
    padding-bottom: 5.4rem !important;
  }
  .dropdown-toggle::after {
    content: "\f282";
    font-family: bootstrap-icons !important;
    border: 0;
    padding: 0;
    font-size: 12px;
    vertical-align: unset;
    line-height: 2;
    position: relative;
    top: 2px;
    left: 2px;
    color: #279f3b !important;
  }
  .main-menu ul > li > a, .nav-link:focus, .main-menu ul > li > button {
    color: white;
    font-weight: 600;
    font-size: 1rem;
    padding: 12px 1rem !important;
    background: none;
  }
  .navbar-nav li:hover .dropdown-menu {
    display: block;
    top: 100%;
  }
  .navbar-nav .dropdown-menu {
    background: #ffffff;
    margin: 0 !important;
    padding: 30px 30px;
    border-radius: 12px;
    min-width: 260px;
    border: 0;
  }
  .navbar-nav .dropdown-menu li a {
    padding: 0 !important;
    line-height: 42px;
    font-weight: 500;
    font-size: 15px;
    color: #686868;
  }
  .navbar-nav .dropdown-menu li a:hover {
    background: none;
    color: #dc2751;
    text-indent: 12px;
  }
  .navbar-nav .dropdown-menu li a.active {
    background: none;
    color: #dc2751 !important;
  }

  .navbar-nav .dropdown-menu li a.active:hover {
    color: #dc2751 !important;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1190px;
  }
  .react-multi-carousel-list {
    margin: 0px -30px;
  }
}
@media (min-width: 681px) and  (max-width: 1024px) {
  .welcome-screen {
    padding: 9rem 0 4rem 0;
  }
  .button-lined {
    margin-left: 12px;
  }
}
@media (max-width: 680px) {
  body {
    font-size: 15px;
  }
  .home {
    background-position: top center;
  }
  .main-blocks {
    margin-top: 30px;
}
  .welcome-screen {
    padding: 8rem 0 4rem 0;
  }
  .feature-column p {
    font-size: 15px;
  }
  .welcome-screen h1 {
    font-size: 3.8rem;
  }
  .feature-column h2 {
    font-size: 2rem;
  }
  .compare-table tr td, .compare-table tr th {
    font-size: 13px;
  }

  .advanced-content .content-box h3 {
    font-size: 1.2rem;
  }
  .bridge-section .card-style-02 .col-3 {
    width: 36% !important;
  }

  .bridge-section .card-style-02 .col-9 {
    width: 64% !important;
  }
  .button-default, .button-lined {
    padding: 0 1.6rem;
    font-size: 15px;
  }
  .button-lined {
    padding: 12px 1.6rem;
    margin-left: 12px;
  }
}

@media (max-width: 440px) {
  .contact-section h2 {
    font-size: 4.4rem;
    line-height: 1;
  }
}
@media (max-width: 400px) {
  .button-default, .button-lined {
    padding: 0px 16px;
  }
  .bridge-section .card-style-02 .col-3 {
    width: 100% !important;
  }

  .bridge-section .card-style-02 .col-9 {
    width: 100% !important;
  }
  .welcome-screen p {
    font-size: 16px;
    margin-bottom: 2rem !important;
  }
}
@media (max-width: 340px) {
  .button-lined {
    margin-left: 0;
    margin-top: 10px;
  }
  .button-default, .button-lined {
    padding: 12px 22px;
  }
}
